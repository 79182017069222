<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && !popupParam.disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-process 
                  required
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  type="edit"
                  label="공정"
                  name="processCd"
                  multiple="single"
                  v-model="data.processCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-dept 
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  type="edit"
                  label="부서"
                  name="deptCd"
                  v-model="data.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="단위작업장소"
                  :disabled="popupParam.disabled"
                  name="workPlace"
                  v-model="data.workPlace">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="작업환경측정결과 정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  :disabled="true"
                  :afterIcon="popupParam.disabled ? null : [
                    { name: 'search', click: true, callbackName: 'searchHazard', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeHazard', color: 'red' }
                  ]"
                  label="유해인자"
                  name="hazardName"
                  v-model="data.hazardName"
                  @searchHazard="openHazard"
                  @removeHazard="removeHazard">
                </c-text>
              </div>
              <div v-if="popupParam.hazardClassFstCd === 'HCF0000005' || popupParam.hazardClassFstCd === 'HCF0000010'" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="CAS NO."
                  name="casNo"
                  v-model="data.casNo">
                </c-text>
              </div>
              <div v-if="popupParam.hazardClassFstCd === 'HCF0000005' || popupParam.hazardClassFstCd === 'HCF0000010'" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="화학물질명"
                  name="chemName"
                  v-model="data.chemName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  required
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  type="number"
                  label="측정치"
                  name="measValue"
                  v-model="data.measValue">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="측정위치(근로자명)"
                  name="measPositionName"
                  v-model="data.measPositionName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-datepicker
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="측정시간"
                  type="time"
                  :minuteStep="10"
                  :range="true"
                  name="measTime"
                  v-model="measTime">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  suffix="회"
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="측정횟수"
                  name="measCount"
                  v-model="data.measCount">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="작업내용"
                  name="workContents"
                  v-model="data.workContents">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  suffix="분"
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="유해인자발생시간(주기)"
                  name="occurTime"
                  v-model="data.occurTime">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  suffix="명"
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="근로자수"
                  name="workCount"
                  v-model="data.workCount">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="근로형태 및 실제근로시간"
                  name="shiftType"
                  placeholder="o조o교대o시간"
                  v-model="data.shiftType">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="발생 형태 및 발생시간(주기)"
                  name="occurType"
                  v-model="data.occurType">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  suffix="TWA"
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="시간가중평균치-전회"
                  name="twaPrev"
                  v-model="data.twaPrev">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  suffix="TWA"
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="시간가중평균치-금회"
                  name="twaCurr"
                  v-model="data.twaCurr">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="popupParam.disabled"
                  label="노출기준"
                  name="exposureStandard"
                  v-model="data.exposureStandard">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-select
                  :disabled="popupParam.disabled"
                  type="edit"
                  codeGroupCd="WORK_EXPOSURE_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="exposureFlag"
                  label="측정농도 평가결과"
                  v-model="data.exposureFlag"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  :disabled="popupParam.disabled"
                  :editable="editable"
                  label="측정 및 분석방법"
                  name="measMethod"
                  v-model="data.measMethod">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 비고 -->
                <c-textarea
                  :disabled="popupParam.disabled"
                  name="remark"
                  label="비고"
                  :rows="2"
                  v-model="data.remark"
                ></c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import CDatepicker from '../../../components/CDatepicker.vue';
export default {
  components: { CDatepicker },
  name: 'work-measure-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        workMeasurementPlanId: '',
        workMeasurementResultId: '',
        hazardClassFstCd: null,
        title: '',
        disabled: false,
      }),
    },
  },
  data() {
    return {
      mappingType: 'POST',
      saveUrl: transactionConfig.sai.workmeas.result.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      data: {
        workMeasurementResultId: '',
        workMeasurementPlanId: '',
        hazardCd: '',
        hazardName: '',
        chemName: '',
        casNo: '',
        processCd: '',
        workAreaGradeCd: '',
        deptCd: '',
        workPlace: '',
        workContents: '',
        workCount: '',
        shiftType: '',
        occurType: '',
        occurTime: '',
        measPositionName: '',
        measCount: '',
        measValue: '',
        twaPrev: '',
        measPlaceName: '',
        twaCurr: '',
        exposureStandard: '',
        exposureFlag: null,
        measTemperature: '',
        measHumidity: '',
        measMethod: '',
        remark: '',
        regUserId: '',
        chgUserId: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      measTime: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.workmeas.result.get.url;
      this.insertUrl = transactionConfig.sai.workmeas.result.insert.url;
      this.updateUrl = transactionConfig.sai.workmeas.result.update.url;

      if (this.popupParam.workMeasurementPlanId) {
        this.data.workMeasurementPlanId = this.popupParam.workMeasurementPlanId;
      }
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.workMeasurementResultId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.workMeasurementResultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (_result.data.measStartTime && _result.data.measEndTime) {
            this.measTime = [_result.data.measStartTime, _result.data.measEndTime];
          }
        },);
      }
    },
    saveData() {
      if (this.popupParam.workMeasurementResultId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              if (this.measTime && this.measTime.length > 0) {
                this.data.measStartTime = this.measTime[0]
                this.data.measEndTime = this.measTime[1]
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.workMeasurementResultId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.workMeasurementResultId = result.data
      this.getDetail();
    },
    openHazard() {
      this.popupOptions.title = this.popupParam.title + ' 검색'; // 유해인자 검색
      this.popupOptions.param = {
        type: 'single',
        hazardClassFstCd: this.popupParam.hazardClassFstCd,
        workEnvFlag: 'Y',
      };
      this.popupOptions.target = () => import(`${'./harmfulFactorsPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPopup;
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.data.hazardCd = data[0].hazardCd;
        this.data.chemName = data[0].chemName;
        this.data.casNo = data[0].casNo;
        this.data.hazardName = data[0].hazardClassSecName + '/' + data[0].hazardName;
      }
    },
    removeHazard() {
      this.data.hazardCd = '';
      this.data.hazardName = '';
    },
  }
};
</script>
